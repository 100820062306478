@import '../../../lib/teacup/depth';
@import '../../../scss/variables.module.scss';

.canvasEntryItem {
    &.revealOpen {
        box-shadow: none;

        .Reveal {
            box-shadow: none;

            .List {
                box-shadow: map-get($depth, 3);
            }
        }
    }
}

.canvas-draghandle-parent {
    cursor: grab;
    border-radius: 2px;
    .canvas-entry-draghandle {
        opacity: 0;
    }

    &:hover {
        .canvas-entry-draghandle {
            opacity: 1;
        }
    }
}

.canvas-popper .MuiTooltip-tooltip {
    padding: 0px;
}

.canvas-entry-2:hover {
    border: 1px solid #828282 !important;
}

.canvas-category-scroll-wrapper::-webkit-scrollbar {
    display: none !important;
}

.canvas-hover-category-effect {
    float: left;
    background-color: $main-background-color;
    transition: background-color 0.2s ease;
}

.canvas-hover-category-effect--locked {
    background-color: rgba($main-button-highlight, 0.02);
}

.canvas-hover-category-effect:hover {
     background-color: #e3e3e3;
}

.canvas-entry-material-textfield .MuiOutlinedInput-root {
    padding: 0;
    outline: none;
    border: none;
}

.canvas-category-title-list:hover .Title {
    font-weight: bold !important; // Todo: remove !important when we remove teacup
}

.LinkToggleIcon .Icon .background {
    background-color: #ededee;
    border-radius: 100px;
    top: 0px;
    left: 0px;
    width: 18px;
    height: 20px;
}

// Let the nubs float correctly
.entryNubHolder {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 5px;
}

.canvasOptionMenuItem {
    color: black !important;
}

.canvasOptionMenuItem:hover {
    background-color: $hover-option-color;
}


.iw-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    float: left;
    display: block;
    width: 15px;
    background-color: #dedede;
    position: relative;
    left: -7px;
    top: -1px;
}
  
.iw-checkbox:checked + span::before {
    content: '\2713';
    text-align: center;
    color: rgba(0,0,0,0.8);
    position: absolute;
    left: 10px;
}

// .iw-checkbox {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     -o-appearance: none;
//     appearance: none;
//     float: left;
//     display: block;
//     width: 15px;
//     background-color: #dedede;
//     position: relative;
//     left: -7px;
//     top: 0px;
// }

// .Mui-selected :after {
//     content: '\2713';
// }

// .iw-checkbox:checked {
//   background-color: #1e445a;
//   border: #dedede 3px solid;
//   border-radius: 5px;
// }

#quick-app-location-selector .react-select-dd {
    min-width: 250px;
    max-width: 350px;
}

.iw-canvas {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.iw-darken {
    background: black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99;
    opacity: 0.6;
    display: block;
}
