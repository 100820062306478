@import './variables.module.scss';
@import './vars-and-mixins.scss';

/*
    Temporary Override to Test Heebo App Wide
*/

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('https://cdn.innovationwithin.services/MaterialIconsOutlined-Regular.otf')
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

.MuiDialog-container .MuiPaper-root {
    overflow: visible;
}

.navigational-hover :hover {
    color: #1565c0 !important;
}

// notification badge color
.MuiBadge-colorError {
    background-color: rgb(198, 40, 40) !important;
    color: white;
}

.MuiTooltip-popper:not(.canvas-popper):not(.NotificationOverviewTooltip) {
    pointer-events: none !important;
}

.no-padding,
.no-padding input {
    padding: 0px !important;
}

.canvas-desc-box .MuiInputBase-root {
    height: 40px;
}

.MuiTypography-root {
    user-select: text;
}

::selection {
    background: rgba(82, 176, 203, 0.6);
}

#root {
    background-color: rgb(250, 250, 250);
}

.canvas-badge-chip-inverter .MuiChip-label {
    color: rgb(250, 250, 250) !important;
}

.appBarSearchBar {
    width: 200px;
}

.appBarSearchBar input {
    background-color: #F7F7F8 !important;
    color: rgb(255, 255, 255) !important;
    border-radius: 4px !important;
    border: 1px solid hsl(0, 0%, 80%) !important;
    padding: 7px !important;
}

.appBarSearchBarIcon {
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 3px;
    z-index: 999;
    color: rgba(0, 0, 0, .3) !important;
    border-right: 1px solid rgba(0, 0, 0, .3);
    height: 30px;
    margin-top: 5px;
    width: 40px !important;
    line-height: 23px;
}

.appBarSearchBarIcon .material-icons {
    top: 5px;
    left: -1px;
    color: black;
    background-color: black;
}

button {
    cursor: pointer
}

button:focus,
[contenteditable="true"] {
    outline: 0;
}

html {
    box-sizing: border-box;
    user-select: none;
    overflow: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.branded::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1e445a;
}

.branded {
    height: 100%;
    display: flex;
    align-items: center;
}

.bp3-label {
    font-weight: normal;
}

// Neomorph!!!
.loginPane {
    display: flex;
    max-width: 800px;
    width: 500px;
    height: 600px;
    margin: auto;
    flex-direction: column;
    color: #1e445a;
    overflow: visible;
    z-index: 100;
}

.genericPane {
    display: flex;
    max-width: 800px;
    width: 500px;
    margin: auto;
    flex-direction: column;
    color: #1e445a;
    overflow: visible;
    z-index: 100;
}

.loginPane h1 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 40;
    text-align: center;
}

.loginPaneContent {
    padding: 20px;
    flex: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 2px;
    background: #eaeaea;
    margin-top: -60px;
}

.loginPaneGeneric {
    padding: 20px;
    flex: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 2px;
    background: #eaeaea;
}

.loginLogo {
    // flex: 35%;
    // height: 100%;
    // display: flex;
    // background-color: rgba(30,68,90,1);
    // align-items: flex-start;
    // flex-direction: column;
    display: block;
    width: 60%;
    margin: auto;
    margin-bottom: 5px;
}

.loginPane .Button:hover {
    transform: scale3d(1.02, 1.02, 1) !important;
}

.forgotPasswordLink {
    color: black !important;
    text-align: right;
}

.loginLogoTitle {
    align-self: center;
    font-size: 40px;
    color: white;
    text-align: center;
    margin-top: 0px;
    line-height: 0.8em;
}

.brandedLink {
    color: white !important;
    text-decoration: underline !important;
}

.brandedLink:hover {
    text-decoration: none !important;
}

.loginLogo img {
    width: 100%;
    height: 100px;
    top: -40px;
    position: relative;
    overflow: visible;
}

.iw-button-icon .Icon {
    float: left;
    margin-right: 5px;
}

.bulkImportCard {
    display: block;
    width: 100%;
    padding: 20px;
}

.loginPaneGeneric h4 {
    text-align: center;
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.edit-interview-bar {
    position: absolute;
    right: 0px;
    top: -45px;
}

.edit-interview-bar .Button {
    height: 32px;
    min-height: 32px;
    line-height: 32px;
}

.loginPaneContent h4 {
    text-align: center;
    font-size: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
}

html,
body {
    height: 100%;
    margin: 0;
    font-size: $body-font-size;
    overflow: hidden;
    background-color: $main-background-color !important;
}

textarea {
    font-family: $font-family;
}

nav,
footer {
    background-color: $header-background;
}

footer {
    color: $header-font-color;
    font-size: 12px;
}

nav {
    a {
        color: $header-font-color;
        text-decoration: none;
    }
}

.btn {
    @include button();
    border: none;
    margin: 5px;
}

::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    width: 12px;
    min-height: 30px;
    border: solid 4px transparent;
    border-radius: 50px;
}

.secondary-font-size {
    font-size: $secondary-font-size;
}

.third-font-size {
    font-size: $third-font-size;
}

// helpers
// .padding-<num>
@for $i from 1 through 100 {
    .padding-#{$i} {
        padding: #{$i}px;
    }
}

// .margin-<num>
@for $i from 1 through 100 {
    .margin-#{$i} {
        padding: #{$i}px;
    }
}

// margin-top<num>
@for $i from 1 through 100 {
    .margin-top-#{$i} {
        margin-top: #{$i}px;
    }
}

// full width and height
.full {
    height: 100%;
    width: 100%;
}

.flex {
    display: flex;
    flex: 1;
}

.iw-title {
    @extend .iw-bold;
}

.iw-bold {
    font-weight: bold;
}

.iw-light {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6) !important; // Todo: Remove this when we gut teacup
}

.font-weight-normal {
    font-weight: normal;
}

.iw-selectable {
    cursor: pointer;
}

.iw-component-input {
    font-size: $body-font-size;
    flex-grow: 1;
}

.iw-component-input-noresize {
    @extend .iw-component-input;
    resize: none;
}

.iw-component-dark {
    color: $primary-font-color-dark;
    background-color: $primary-background-dark;
}

.iw-component-input-minimal {
    outline: 0;
    border: 0;
    background-color: transparent;
    white-space: pre-wrap;
}

.iw-component-input-minimal .MuiOutlinedInput-root,
.iw-component-input-minimal .MuiOutlinedInput-notchedOutline {
    outline: 0;
    border: 0;
    background-color: transparent;
}

// IWT-1645: Add a nice dropdown indictator to the profile menu
.userSettingsDropdownInc {
    position: absolute;
    margin-left: 27px;
    z-index: 10000000;
    bottom: -17px;
    pointer-events: none;
}

// The style for the branded "blue and white" actionable buttons
.iw-button {
    color: rgb(250, 250, 250) !important;
    background-color: #1565c0 !important;
}

.iw-button-gray {
    color: #0D0D0D !important;
    background-color: white !important;
}

// Give the button a "disabled" look
.iw-button-disabled {
    opacity: 0.4;
    color: #0D0D0D !important;
    background-color: white !important;
}

.iw-button-dark-gray {
    color: #0D0D0D !important;
    background-color: rgb(224, 224, 224) !important;
}

.iw-button-group button {
    margin-left: 0px;
    margin-right: 0px;
    border-radius: 0px;
    border: 1px solid #c7c7c7;
    outline: 0;
    height: 20px;
    min-width: 30px;
}

.iw-button-group button:last-child {
    border-radius: 0px 5px 5px 0px;
}

.iw-button-group button:first-child {
    border-radius: 5px 0px 0px 5px;
}

.iw-button-group {
    margin-left: 5px;
}

.discovery-note-editor {
    background: white;
    margin-left: 10px;
    margin-right: 10px;
    user-select: text;
    height: calc(100vh - 131px);
    border: 1px solid $border-grey;
    padding: 10px !important;
    border-radius: 0px;
}

#__next {
    height: 100%;
}

.OrgTreeList .selected {
    background-color: rgb(100, 111, 121) !important;
}

::placeholder {
    opacity: 1;
}

[role~="tooltip"] {
    z-index: 1000;
}

.iw-finding-region {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.iw-chat-region {
    border-left: 1px solid $border-grey;
    background-color: white;
}

.iw-wiki-section {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.23);
}

.focusModeIcon {
    top: 5px;
    left: 2px;
    fill: rgb(250, 250, 250);
}

// When an icon is inside a button we need to apply some special styling to it
.iconButton {
    display: inline-block !important;
    color: rgb(250, 250, 250) !important;
    position: absolute !important;
    top: 0px;
    left: 5px;
}

.artifact-circular-loader {
    position: absolute;
    height: 25px !important;
    top: 7px;
    left: 615px;
    width: 25px !important;
}

.iconButton path {
    fill: rgb(250, 250, 250);
}

.iconFlipped i {
    transform: rotate(180deg);
}

// When a toolbar is set to "hideIconsOnHover" we should 
// hide any attached elements within it, till the parent is hovered
.hideIconsOnHover .attached {
    display: none;
}

.hideIconsOnHover:hover .attached {
    display: inline-block;
}

.box-sizing-inherit {
    box-sizing: inherit;
}

.cursor-text {
    cursor: text;
}

.text-centered {
    text-align: center;
}

.rdtPicker {
    position: relative;
}

.iw-table .rt-tr-group:nth-child(even),
.iw-table tr:nth-child(even) {
    background-color: #f5f5f5;
}

.iw-table .cs-tr-group:nth-child(even) {
    background-color: #f5f5f5;
}

.iw-table {
    text-align: center;
}

.iw-table-mass-import .rt-resizable-header {
    overflow: visible !important;
}

.iw-table-overview {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-collapse: collapse;
}

.iw-table-overview tr td:first-child {
    text-align: left;
}

.iw-table-overview tr td {
    padding: 2px;
}

/*
    START MINIMAL TABLE
    we've been in need of a simple, consistent stylized minimal table for
    non data-intesive tasks, but ones that deal with table aligned data
    e.g. the cohort periods widget
*/

.iw-table-minimal input {
    outline: none !important;
    border: none !important;
    background-color: transparent !important;
    // overflow-y: scroll !important;
}

/*
    END MINIMAL TABLE
*/

.legend-star-component {
    width: 90%;
}

.Dropdown .List {
    border: 1px solid #d1d1d1;
    padding: 5px;
}

.bmcContextMenu .List {
    border: 1px solid #d1d1d1;
}

.bmcContextMenu .ListItem:hover {
    background-color: #e5e5e5;
}

.commentActionLink {
    color: rgb(17, 85, 204);
}

.commentActionLink:hover {
    text-decoration: underline;
}

// Basic CSS hover
.hideWithoutHover {
    display: none;
}

.hideWithoutHoverParent:hover .hideWithoutHover {
    display: inline-block;
}

/* General csv reader input style */
.csv-reader-input {
    margin-top: 10px;
    display: block;
    width: 100%;
}

.iw-table-full-height .rt-table {
    height: 100%;
}

.iw-table-full-height-scrollable {
    height: 100%;
}

.iw-table-full-height-scrollable .rt-tr-group {
    max-height: 67px;
}

.iw-table .rt-td {
    align-self: center;
}

// Ensure the mass-upload table headers always show there dropdowns
.iw-table-mass-import .rt-resizable-header-content {
    overflow: unset !important;
}

.light-placeholder ::placeholder {
    color: #474747 !important;
}

.iw-component-input-minimal::placeholder {
    color: #b3b3b3;
}

// When you select the new comment region of the 
// comments dialog give it a blue border    
.iw-new-comment-input:focus {
    border: 2px solid #1565c0 !important;
}

.insightSubQuote:hover {
    color: #1565c0 !important;
    text-decoration: underline !important;
}

.discovery-hypothesis-link-canvas-label:hover {
    color: black !important;
    background-color: $hover-option-color;
}

.slate-format-button:hover {
    cursor: pointer;
    color: black !important;
    border-radius: 4px;
}


.MuiTableCell-body, .MuiTableCell-root {
    font-size: 1rem !important;
}

.discovery-hypothesis-link-canvas-label-active {
    color: rgb(250, 250, 250) !important;
    background-color: #1565c0 !important;
}

.discovery-hypothesis-link-canvas-open {
    background-color: #1565c0 !important;
    color: rgb(250, 250, 250) !important;
    border-radius: 5px;
    padding: 8px !important;
    padding-bottom: 0px !important;
    padding-top: 4px !important;
}

.textbox-reveal-on-hover textarea {
    border: none !important;
}

.textbox-reveal-on-hover textarea:hover {
    background-color: #ecebeb !important;
    border-radius: 5px !important;
}

.textbox-reveal-on-hover textarea:focus {
    border: 2px solid #1565c0 !important;
    border-radius: 5px !important;
}

.revealWithBorderRadius {
    border-radius: 2px !important;
    border: 1px solid rgb(130, 130, 130) !important;
}

.canvasSlider .rc-slider-handle:focus {
    border: 2px solid #1565c0 !important;
    border-radius: 5px !important;
}

.cohortPeriodsTable .rt-tr-group {
    max-height: 56px;
}

.float-right {
    float: right;
}

// Checkbox styling
input[type="checkbox"] {
    height: 16px;
    line-height: 16px;
    margin: 8px 0 8px 0;
    border-radius: 3px;
}

// React-table style to replace the border
// with arrows
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6)
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6)
}

.iw-table-no-overflow .rt-td {
    overflow: visible !important;
}

div.-sort-desc::after {
    content: " \25BC";
    overflow: hidden;
    position: absolute;
    right: 10px;
}

div.-sort-asc::after {
    content: " \25B2";
    overflow: hidden;
    position: absolute;
    right: 10px;
}

.rt-resizable-header-content {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    width: 80%;
}


// Prevent the quill editor from scrolling on it's own
// #quill-transcript-editor-container .ql-editor {
//     height: unset !important;
//     overflow-y: unset !important;
// }

// #quill-transcript-editor-container .quill {
//     height: unset !important;
//     padding-left: 20px;
// }

// // Force the transcription parent container to scroll
// #quill-transcript-editor-container {
//     overflow-y: scroll;
// 

.transcript-decorator-active, .transcript-v3-activeword {
    background-color: #1c2127;
    color: white;
    margin: -3px;
    padding: 3px;
}

.remirror-editor {
    padding-top: 6px !important;
    padding-bottom: 10px !important;

    p {
        &,
        span {
            background: transparent !important;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

.remirror-theme {
    display: flex;
    flex-direction: column;

    .ProseMirror {
        box-shadow: 0 0 0 1px $border-grey !important;
    }
}

.remirror-editor-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 1px 1px;
}

// .remirror-editor-wrapper .remirror-editor {
//     height: calc(100% - 65px);
// }

.ProseMirror {
    background-color: white;
    border-radius: 4px !important;
    height: 100%;
    display: block !important;
    overflow-y: scroll;
    // flex-direction: column;
}

.resources-active {
    border-bottom: 2px solid #1976D2;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    color: #1976D2;
}

.resources {
    color: rgba(0,0,0,0.6);
}

.resources:hover {
    color: $main-button-highlight;
    border-bottom: 2px solid $main-button-highlight;
}

.remirror-is-empty {
    height: 100%;
    display: block;
}

.ProseMirror:focus {
    outline: 2px solid rgb(25, 118, 210) !important;
    box-shadow: none !important;
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

// The hover menu's for setting & displaying what the current speaker is
.speakerGutterSelection {
    margin-bottom: 0px;
    margin-top: 5px;
    height: 28px;
    display: block;

    // Make it unselectable
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;

    position: relative;
    display: block;
    left: -30px;
    padding: 3px;
    border-radius: 5px;
}

.white-badge-text {
    color: white !important;
}

.appbar-nav-item-badge {
    top: 2px !important;
    right: 2px !important;
    height: 18px !important;
    line-height: 18px !important;
}

.MuiRating-root:not(.ScoringRating, .InsightPageRating) .MuiRating-iconFilled svg {
    fill: #1976D2 !important;
}

#transcript-parent {
    .transcript-leaf-word {
        display: inline-block;
        line-height: 24px;
        min-height: 24px;
        transition: background 0.05s ease-in-out, color 0.05s ease-in-out, border-radius 0.05s ease-in-out;

        &--insight {
            background-color: rgb(255, 249, 179);
            color: $text;
        }

        &--highlighted {
            background-color: $text-selection-color;
            color: $text;
        }
    }

    .transcript-editor-speaker-element {
        transition: color 0.2s ease;

        &__time {
            color: $main-icon-color;
        }

        &__play-button {
            .play-icon {
                display: block;
            }

            .pause-icon {
                display: none;
            }

            .MuiSvgIcon-root {
                color: $main-icon-color;
            }
        }

        &__loader {
            opacity: 0;
            transition: opacity 0.15s ease;
            height: 28px;
            width: 40px;
        }

        &--active {
            color: $main-button-highlight;

            .MuiSvgIcon-root {
                color: $main-button-highlight;
            }

            .MuiDivider-root {
                border-color: $main-button-highlight;
            }

            .transcript-editor-speaker-element {
                &__container--editing {
                    box-shadow: 0 0 0 1px $main-button-highlight;
                }

                &__popup .MuiInputBase-input {
                    -WebkitTextFillColor: $main-button-highlight !important;
                }
                
                &__time {
                    color: $main-button-highlight;
                }
            }
        }

        &--talking.transcript-editor-speaker-element--active {
            .transcript-editor-speaker-element {
                &__play-button {
                    .play-icon {
                        display: none;
                    }

                    .pause-icon {
                        display: block;
                    }
                }

                &__loader {
                    opacity: 1;
                }
            }
        }
    }

    .current-active-word {
        background: $main-button-highlight !important;
        color: $white !important;
        padding: 1px 0 2px 4px;
        margin: -1px 0 -2px -4px;
        border-radius: 4px;
        position: relative;
        z-index: 1;

        &--paused {
            padding: 1px 0 2px 4px;
            margin: -1px 0 -2px -4px;
            border-radius: 4px;
            position: relative;
            z-index: 1;
            animation: blink 1s ease infinite;
        }
    }

    .selected-insight-word {
        box-shadow: inset 0 -3px 0 0 $main-button-highlight;
        border-top: 3px solid $main-button-highlight;
        border-radius: 0;
        margin-top: -3px !important;
        padding-bottom: 3px !important;

        &--first {
            border-left: 3px solid $main-button-highlight;
            padding-left: 6px;
        }

        &--last {
            border-right: 3px solid $main-button-highlight;
            padding-right: 3px;
        }

        &.current-active-word {
            padding: 0 0 0 3px;
            margin: 0 0 0 -3px;
        }

        &.current-active-word--paused {
            padding: 0 0 0 3px;
            margin: 0 0 0 -3px;
        }
    }

    p {
        margin: 0;
    }
}

.speakerGutterPopover {
    z-index: 1000000000;
}

// Auto suggest component style
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 100%;
    height: 30px;
    padding: 10px 30px;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input:focus {
    outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.icon-button-compact {
    padding: 5px !important;
}

.react-autosuggest__suggestions-container {
    position: absolute;
    top: 29px;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
}

.react-autosuggest__suggestions-list li {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 0.9em;
    border-bottom: 1px solid #e8e8e8;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--focused {
    background-color: #ddd;
}

.react-autosuggest__container .Icon {
    position: absolute;
    left: 5px;
    top: 0px;
}

.input-hide-till-focus input {
    border: none !important;
}

.input-hide-till-focus input:focus {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.overflow-Toolbar {
    overflow: visible;
}

.discovery-control-select .react-select-dd {
    width: 340px;
}

// Override some react select styles
.react-select-dd {
    cursor: pointer !important;
}

.react-select-dd-floatright {
    float: right !important;
    margin-right: 5px;
}

.react-select-dd input {
    border: none;
    color: transparent !important;
    text-shadow: 0 0 0 #0D0D0D;
    text-align: center;

    &:focus {
        outline: none;
    }
}

.react-select-bmc input {
    text-shadow: 0 0 0 white !important;
}

.progressbar-container {
    background-color: #ee918f !important;
    display: block;
    width: 100%;
    height: 100%;
}

.progressbar-progress {
    background-color: #a5d6a7 !important;
    height: 100% !important;
}

// Don't elips the text in the wiki seen table headers
.iw-wiki-seen-table .rt-resizable-header-content,
.iw-wiki-seen-table .rt-th {
    white-space: unset;
}

.iw-wiki-seen-table .rt-tbody .rt-tr {
    max-height: 40px;
    height: 40px;
}

.iw-org-dropdown {
    width: 100%;
    line-height: 24px;
    position: relative;
    // top: 13px;
    // left: 3px;
    // z-index: $menu-box-shadow - 1;
}

.notificationLinkText:hover {
    color: #1565c0 !important;
    text-decoration: underline !important;
}

.discoveryToggleParent .react-toggle {
    top: 7px !important;
    left: 7px !important;
}

.discoveryToggleParent {
    left: 34px;
    position: relative;
}

.customButton {
    cursor: pointer;
}

.customButton:hover {
    background-color: #cccccc !important;
}

// .floatingQuillWrapper {
//     display: block;
//     margin: 10px;
//     border: 1px solid #d9d9d9;
//     border-radius: 10px;
//     position: relative;
//     height: 90%;
//     padding-bottom: 40px;
// }

// .floatingQuillWrapper .ql-toolbar {
//     border: none !important;
//     border-bottom: 1px solid #ccc !important;
// }

.iw-chat-button-wrapper {
    margin-right: 5px !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    height: 39px !important;
    line-height: 39px !important;
    padding: 7px;
    width: 50px;
    border-radius: 5px;
    display: inline-block !important;
}

.MuiPaper-root {
    box-shadow: none !important;
}

.recordingNavigateWarning {
    background-color: rgb(255, 249, 179);
    padding: 20px;
    width: 400px;
    display: block;
    margin: auto;
    margin-top: 10px;
    padding-bottom: 5px;
    padding-top: 15px;
    margin-bottom: 10px;
}

.recordingNavigateWarning .Icon {
    float: left;
    padding-right: 20px;
}

.iw-chat-button-wrapper-active {
    padding: 5px !important;
    border: 2px solid white !important;
}

.iw-chat-button {
    margin: 0px !important;
    height: 31px !important;
    line-height: 31px !important;
    margin-top: -3px !important;
    margin-left: -3px !important;
    color: white !important;
    width: 42px;
    min-width: 42px;
    font-weight: bold !important;
    background-color: #272B2B !important;
    overflow: visible !important;
}

.special-badge {
    height: 20px !important;
    right: -10px !important;
    top: -5px !important;
}

.iw-new-chat-input {
    padding: 7px;
}

// Make sure our blueprint portal is rendering correctly
.bp3-portal {
    z-index: 9999999 !important;
}

.new-feature-popover-backdrop {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.bp3-popover-content {
    padding: 15px;
}

.draft-interview-bar {
    display: block;
    position: absolute;
    bottom: 0px;
    background-color: #d9d9d9;
    width: 100%;
    height: 52px;
}

/*
    Setup a generalized class we can use to deal with
    removing a teacup "Layout" page, and not having it
    break with a Teacup Toolbar being injected by a 
    higher level component
    // TODO: I have found on occasion, that some high level toolbars
    // are set in a positiong mannor that breaks relative low
    // down the DOM.  If you're having trouble with this and
    // found this message.  Check into margin-top?  Or toy with
    // fixing the position hellscape that exists at the top
    // most levels of the app
*/

.iw-non-layout-page {
    position: relative;
    top: 40px;
    padding: 20px;
}

// Universale "invalid" input
.input-invalid {
    border: 1px solid #ee918f;
}

.DatetimeInput .form-control {
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 32px;
    padding: 0 4px;
    font-size: 1rem;
    color: #0D0D0D;
}

.MuiPaper-root:not(.MuiSnackbarContent-root, .MuiAlert-root) {
    background-color: #fff;
    box-shadow: none !important;
    //   color: inherit !important;
    //  -webkit-box-shadow: 0px 0px 45px -22px rgba(0,0,0,1) !important;
    //  -moz-box-shadow: 0px 0px 45px -22px rgba(0,0,0,1) !important; 
}

.MuiPopover-paper:not(.MuiSnackbarContent-root), .MuiAutocomplete-listbox {
    box-shadow: $menu-box-shadow !important;
}

#uppy-dashboard-containerheader {
    display: block;
    width: 100%;
    height: 40px;
    background-color: #1c445b;
}

#draggable-uppy-dashboard-container {
    position: absolute;
    top: 400px;
    left: 400px;
    height: 600px;
    width: 700px;
    display: none;
    cursor: move;
    padding-top: 20px;
    background-color: #1c445b;
    border-radius: 4px;
}

#uppy-dashboard-container {
    display: block;
    width: 100%;
    cursor: default;
    height: 100%;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background: #f7f7f7;

    border: 1px solid #dcdcdc;
    padding: 15px;
    overflow-y: scroll;
}

#uppy-dashboard-container img {
    width: 50px !important;
    height: 50px !important;
}

.uppy-c-btn-primary {
    background-color: rgb(36, 114, 173);
}

.uppy-Dashboard-poweredBy {
    display: none !important;
}

.uppy-DashboardContent-back {
    color: rgb(36, 114, 173) !important;
}

.uppy-Dashboard-browse {
    color: rgb(36, 114, 173) !important;
}

.MuiDialogContent-root {
    background-color: white;
}

.canvas-hover-category-effect .MuiDialogContent-root {
    background-color: initial;
}

.transcript-saving {
    z-index: 10000;
    position: absolute;
    top: 10px;
    left: calc(50% - 20px);
}

.notes-saving {
    z-index: 10000;
    position: absolute;
    top: 10px;
    left: calc(50% - 20px);
}

.material-org-tree .MuiTypography-root {
    background-color: transparent !important;
}

.material-org-tree .MuiListItem-root {}

// .discovery-note-editor:first-child {
//     height: 100%;
//     padding: 15px;
// }


.material-wiki-section-root .MuiPaper-root {
    height: max-content !important;
}

.material-wiki-section-root .Draggable {
    height: max-content !important; 
}

.top-level-discovery {
    // box-shadow: none !important;
    border: 1px solid $border-grey !important;
    border-radius: 4px !important;
}
.insight-creation-active{
    border: 2px solid #1976D2 !important;
}
.insight-creation-tags{
    &:hover {
        border: 1px solid #1976D2 !important;
    }
}

.MuiAccordion-root {
    border: 1px solid $border-grey;
}

.MuiAccordion-root:hover {
    border: 1px solid $main-button-highlight;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg) !important;
}

.MuiAccordionSummary-content {
    margin: 10px 0px !important; 
    justify-content: space-between;
}

.MuiAccordionSummary-root {
    padding: 0px !important;
    flex-direction: row-reverse;
}

.MuiTooltip-tooltip {
    font-size: 15px !important;
    padding: 5px 8px !important;
    margin-top: 8px !important;
}

#teamInfoTooltip .MuiTooltip-tooltip, .MuiAutocomplete-listbox {
    background-color: white !important;
    color: $primary-font-color;
    border: 1px solid $border-grey !important;
    box-shadow: $menu-box-shadow !important;
}

.canvas-popper .MuiTooltip-tooltip {
    padding: 0px !important;
    margin: 0px !important;
}

.MuiButton-outlined.MuiButton-outlinedPrimary:hover {
    border-color: $main-button-highlight !important;
}

.MuiButton-contained {
    box-shadow: none !important;
}

.MuiButton-startIcon {
    margin-left: 0px;
}

.MuiTabs-root:not(.InterviewListTab, .Interview-Documents-Tab, .wiki-tabs) {
    display: block;
    overflow: hidden;
    line-height: 40px !important;
    font-weight: 500;
    height: 40px !important;
    font-size: 1.0rem !important;
    height: 40px !important;
    min-height: 40px !important;
    line-height: 40px !important;
    position: relative;

    .MuiTabs-indicator {
        text-transform: none;
    }
    .Mui-selected .MuiTouchRipple-root {
        display: none !important;
    }
    .Mui-selected {
        border-bottom: 2px solid;
        font-weight: 600 !important;
        opacity: 1.0;
    }
    .MuiButtonBase-root {
        height: 40px !important;
        min-height: 40px !important;
        line-height: 40px !important;
        margin: 0px;
        font-weight: 400;
        font-size: 1rem !important;
        padding: 0px;
        text-transform: capitalize !important;
        margin-right: 5px;
        margin-left: 5px;
        border-bottom: 2px solid transparent;
    }
    .MuiButtonBase-root:hover {
        color: #1976D2 !important;
        border-bottom: 2px solid #1976D2 !important;
    }
    .Mui-selected:hover {
        color: #1976D2 !important;
        font-weight: 700 !important;
    } 
}

.lightbulb-tooltip .MuiTooltip-tooltip {
    // color: red !important;
    // background-color: red !important;
    margin-top: 0px !important;
}

.app-root-navigation.MuiTabs-root .MuiButtonBase-root {
    min-height: 25px !important;
    line-height: 25px !important;
    height: 25px !important;
    max-height: 25px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.app-root-navigation .MuiButtonBase-root:hover {
    font-weight: 400;
}

.dirty-insight-editor-clickblocker-effect {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

.dirty-insight-editor-clickblocker-effect--deactivate {
    z-index: 2;
}

.dirty-recorder-clickblocker-effect {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

.dirty-recorder-clickblocker-effect--deactivate {
    z-index: 2;
}

@keyframes blink {
    0% {
        background: $hover-option-color;
        color: $text;
    }

    50% {
        background: $main-button-highlight;
        color: $white;
    }
    
    100% {
        background: $hover-option-color;
        color: $text;
    }
}
